import { createGlobalStyle } from 'styled-components';

export const breakpointsByNumber = {
  xs: 520,
  sm: 820,
  md: 1024,
  lg: 1280,
  xl: 1640,
};

export const breakpoints = {
  xs: `${breakpointsByNumber.xs}px`, // 520px
  sm: `${breakpointsByNumber.sm}px`, // 820px
  md: `${breakpointsByNumber.md}px`, // 1024px
  lg: `${breakpointsByNumber.lg}px`, // 1280px
  xl: `${breakpointsByNumber.xl}px`, // 1640px
};

// Theme object for Styled Components
export const theme = {
  breakpoints,
};

export const GlobalStyle = createGlobalStyle`
  :root {
    // General
    --border-radius: 0.35rem;
    --border-radius-large: 1rem;
    --shadow: 0 0 10px rgba(0,0,0,.1), 0 0 3px rgba(0,0,0,.16);
    --shadow-large: 0 0 20px rgba(0,0,0,.12), 0 0 6px rgba(0,0,0,.16);
    --max-width-desktop: 1280px;
    --narrow-width-desktop: 1024px;
    --extra-narrow-width-desktop: 720px;
    --animation-duration: 200ms;
    --animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --header-height: 5rem;

    // Fonts
    --primary-font: "Nunito Sans", "Helvetica Neue", -apple-system, sans-serif;
    --code-font: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

    // Colors
    --background-color: #f6f7f9;
    --foreground-color: #fff;
    --primary-color: #236dde;
    --primary-color-shade: #0a5acb;
    --primary-color-highlight: #2196f3;
    --primary-color-subtle: #f5f8ff;
    --primary-color-subtle-highlight: #EDF2FF;
    --primary-color-faded: #cde1fe;
    --subtle-color: #dde1e9;
    --placeholder-color: #a3a3a3;
    --grey-color: #333D47;
    --grey-light-color: #6C7884;
    --text-color: var(--grey-color);

    // Breakpoints
    --breakpoint-xs: ${breakpoints.xs};
    --breakpoint-sm: ${breakpoints.sm};
    --breakpoint-md: ${breakpoints.md};
    --breakpoint-lg: ${breakpoints.lg};
    --breakpoint-xl: ${breakpoints.xl};
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }

  html, body {
    height: 100%;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.sm}) {
      font-size: 13px;
    }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    background-color: var(--grey-color);
    color: var(--text-color);
    font-weight: 400;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  #gatsby-focus-wrapper, #___gatsby {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  div {
    box-sizing: border-box;
  }

  ul, ol {
    margin: 0 0 1rem;
    padding: 0 0 0 2rem;
  }

  li {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  main li {
    margin-bottom: 0.5rem;
  }

  a, a:hover {
    text-decoration: none !important;
  }

  main a:not(:has(button)) {
    color: var(--primary-color);
    /* font-weight: 700; */
    transition: color var(--animation-duration) var(--animation-timing-function);
    border-bottom: 2px solid var(--primary-color);

    &:hover {
      color: var(--primary-color-highlight);
      border-bottom-color: var(--primary-color-highlight);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--primary-font);
    font-weight: 700;
    line-height: 1.3;
    margin: 0;
  }

  h1 {
    font-weight: 800;
    font-size: 2.75rem;
    line-height: 1.13;
    letter-spacing: -0.91px;
    margin-bottom: 1rem;
  }

  h2 {
    font-weight: 800;
    font-size: 2.75rem;
    line-height: 1.13;
    letter-spacing: -0.91px;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.25rem;
    letter-spacing: -0.12px;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1rem;
    letter-spacing: -0.12px;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1rem;
    letter-spacing: -0.11px;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
    margin-bottom: 1.5rem;
    letter-spacing: -0.09px;

    &.large {
      font-size: 1.25rem;
    }
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  label {
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.sm}) {
      font-size: 1.1rem;
    }
  }

  input, button, textarea, select {
    font: inherit;
  }

  input, textarea {
    color: var(--text-color);
    border: 2px solid var(--subtle-color);
    border-radius: var(--border-radius);
    padding: 0.4rem 0.6rem;
    width: 100%;

    &::placeholder {
      color: var(--placeholder-color);
    }
  }

  input.error,
  .error,
  .error:focus-visible {
    border: 2px solid #db4343 !important;
  }

  input[type="checkbox"] {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
    vertical-align: baseline;

    &.error {
      // checkbox can't have border, so use an inset box shadow with radius
      box-shadow: inset 0 0 0 2px #db4343;
    }
  }

  input[type="submit"] {
    padding: 0.6rem 1.4rem;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all var(--animation-duration) var(--animation-timing-function);
    background-color: var(--primary-color);
    color: var(--foreground-color);
    width: auto;

    &:disabled {
      background-color: var(--subtle-color);
      color: var(--grey-color);
      cursor: not-allowed;
    }
  }
`;
